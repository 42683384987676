/*************************************************************************
*
* Customize Bootstrap 4 with our new built-in Sass variables for 
* global style preferences for easy theming and component changes.
*
* Please read more at https://getbootstrap.com/docs/4.0/getting-started/theming/
* 
*************************************************************************/

@debug rgba(#fff, 0.8);

$theme-colors: (
  primary: #283fa5,
  secondary: rgb(12, 46, 61),
  buttonsBg: #283fa5,
  buttonsColor: #ffffff,
  bgColor: #fff,
  bgSecondary: #c7dbf4,
  bgGradient: #283fa5,
  cardBg: #ffffff,
  cardBgOpacity: #ffffff67,
  textColor: #000,
  textColorLight: #fff,
  darkColor: #869aff,
);
