// FORM
// ._field11 {
//   position: absolute !important;
//   bottom: -70px;
// }
._form_1 {
  &:nth-child(1) {
    max-width: 100%;
    width: 100%;
    border: 1px solid theme-color('bgColor');
    padding: 4em;
    border-radius: 25px;

    background-color: theme-color('bgColor');
  }
}

._form {
  width: 100% !important;
  padding-left: 0 !important;
  margin-top: 0 !important;
  background-color: transparent !important;

  * {
    font-family: 'Roboto';
    font-weight: 400;
  }

  ._field-wrapper input {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important ;
    border-radius: 0 !important;
  }
  ._field-wrapper textarea {
    resize: vertical;
    border-radius: $borderDefault;
  }
  ._field-wrapper input,
  ._field-wrapper textarea {
    border-color: theme-color('primary') !important;
    color: theme-color('primary') !important;
    // FONT
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    @media (max-width: 1600px) {
      & {
        font-size: 1.3vw;
        line-height: 1.5vw;
      }
    }
    @media (max-width: 991px) {
      & {
        font-size: 3.5vw !important;
        line-height: 4.5vw;
      }
    }
    line-height: 24px;

    &::placeholder {
      color: theme-color('textColor');
      // opacity: 0.5;
      overflow: visible;
      line-height: normal !important;
    }
    &::-webkit-input-placeholder {
      line-height: normal !important;
      color: theme-color('textColor');
      // opacity: 0.5;
      overflow: visible;
    }
  }

  ._form_element {
    margin-bottom: 2vw;
  }
}

._form-label {
  text-transform: uppercase;
  font-family: 'Open Sans';
}

#_form_613F376A26FD8_._inline-style {
  width: 100%;
  display: inline-block;
}

._x64975569 input {
  color: #fff;
}

// ._field9 select {

// }
._form_element ._html-code p {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;

  @media (max-width: 991px) {
    & {
      font-size: 3vw;
      line-height: 3.5vw;
    }
  }
}
._field-wrapper input {
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  // height: 50px !important;
  font-size: 1.2em;
}

._form-fieldset {
  display: flex;
  @media (max-width: 991px) {
    & {
      display: block;
    }
  }
}

#_form_1_submit {
  background-color: theme-color('primary') !important;
  border: 1px solid theme-color('primary') !important;
  @media (max-width: 1600px) {
    & {
      font-size: 1.3vw;
      line-height: 1.5vw;
    }
  }

  &:hover {
    cursor: none;

    color: theme-color('primary') !important;
  }
  &:focus {
    outline: none;
  }
}

._error-inner._no_arrow {
  display: none;
}

._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 0 !important;
}

._button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;

  padding-top: 2em;

  @media (max-width: 991px) {
    & {
      padding-top: 1em;
    }
  }
}

._inline-style:not(._clear) + ._inline-style:not(._clear) {
  margin-left: 20px !important;
}

._inline-style input[type='text'],
._inline-style input[type='date'] {
  padding: 10px 28px !important;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: theme-color('textColor') !important;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

._form-thank-you {
  text-align: left !important;
}

// ._field11 {
//   position: absolute !important;
//   bottom: -40px;
// }
._form {
  padding-top: 0 !important;
}

input[type='checkbox']._has_error {
  outline: theme-color('primary') 1px solid !important;
  animation: shake 0.5s;
}

._form_element._clear {
  margin-bottom: 0 !important;
}

.policyStyle {
  color: #fff;
  text-decoration: underline !important;
}

@media (max-width: 991px) {
  ._form_1 {
    &:nth-child(1) {
      // border: none !important;

      padding: 7vw 5vw;
      border-top: none;
      border-bottom: none;
    }
  }
}
