/*******************************
*
* Customize your CSS here
*
********************************/

body {
  background: theme-color('bgGradient');
}

.textCentered {
  text-align: center;
}

//  CARDS
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// $grid-gutter-width: 30px;
$grid-gutter-width: 30px;

$cards-per-line: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3,
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .aboutMeBiddingCards {
      flex: 0 0
        calc(#{100 / map-get($cards-per-line, $name)}% - #{$grid-gutter-width});
    }
  }
}
